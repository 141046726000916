import PrimaryButton from '../../components/PrimaryButton';
import { colors } from '../../global/variables';
import { open3dsModal } from '../../utils/webview/messages';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIcon.svg';
import { useAppSelector } from '../../app/hooks';
import { selectAccessToken } from '../../appSlice';
import { useEffect } from 'react';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useParams, useSearchParams } from 'react-router-dom';

const retryRemoteStartQuery = loader('../../gql/remoteStart.graphql');
const StartRedirectPage = () => {
    const accessToken = useAppSelector(selectAccessToken);
    const returnToApp = () => {
        open3dsModal(undefined);
    };
    const params = useParams();
    let [searchParams] = useSearchParams();

    const [
        retryRemoteStart,
        { data: retryRemoteStartData, loading: retryRemoteStartLoading },
    ] = useMutation(retryRemoteStartQuery);

    useEffect(() => {
        const performActions = async () => {
            if (accessToken) {
                console.log(accessToken);
                try {
                    const paymentIntentId =
                        params.payment_intent ||
                        searchParams.get('payment_intent');
                    const qrCode =
                        params.payment_intent ||
                        searchParams.get('payment_intent');

                    await retryRemoteStart({
                        variables: {
                            qrCode: qrCode,
                            paymentIntentId: paymentIntentId,
                        },
                    });
                } catch (e) {
                    console.log(e);
                }
            }
        };
        performActions();
    }, [accessToken]);

    return (
        <div className="container" id="payment_redirect_page">
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: 40,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                    }}
                    id="payment_redirect_close_container"
                >
                    <div onClick={returnToApp} id="error_modal_close">
                        <CloseIcon
                            fill={colors.inactiveWhite}
                            id="error_modal_close_icon"
                        />
                    </div>
                </div>
                <div style={{ marginBottom: 20, textAlign: 'center' }}>
                    Verification has been sent to your back for approval.
                </div>
                <PrimaryButton
                    disabled={retryRemoteStartLoading}
                    title={'Continue'}
                    onPress={() => returnToApp()}
                />
            </div>
        </div>
    );
};

export default StartRedirectPage;
