import { differenceInMinutes, parseISO } from 'date-fns';
import { gql, loader } from 'graphql.macro';
import { store } from '../../app/store';
import {
    FinishedTransaction,
    setAccessToken,
    setActiveChargieId,
    setAllowedLocationData,
    setAppleAuthExists,
    setBiometricsExists,
    setBiometricsRefreshToken,
    setBiometricsType,
    setCarrier,
    setChargeStatus,
    setDesiredRFID,
    setDeviceGuid,
    setDeviceToken,
    setDeviceType,
    setEmail,
    setFinishedTransaction,
    setFirstName,
    setGooglePlayServicesExists,
    setHasPaymentMethod,
    setInactiveChargieId,
    setIsSubscribed,
    setLastName,
    setLatitude,
    setLongitude,
    setModalBackground,
    setNetInfo,
    setPlatformOs,
    setPlatformPayExists,
    setRefreshToken,
    setRequestedChargieId,
    setSubscriptionStarted,
    setVersion,
} from '../../appSlice';
import {
    setChargingHistory,
    setRecentStations,
} from '../../containers/HomePage/slice';
import { setCreditCard, setPaymentMethod } from '../../guestSlice';
import client, { generateHeaders } from '../api';
import { axios } from '../axios';
import {
    ChargeStatus,
    SecureStorageKeys,
    StartMethod,
    WebViewMessageType,
} from '../enums';
import router from '../router';
import { ChargieMessage, logToNative, saveData } from './messages';

const getLatestUserDataQuery = loader('./getLatestUserData.graphql');
interface LoadDataPayload {
    key: string;
    value: string | null;
}

interface DeleteDataPayload {
    key: string;
}

let scanned = false;

async function messageHandler(event: MessageEvent) {
    try {
        const receivedData: ChargieMessage = JSON.parse(event.data);
        switch (receivedData.action) {
            case WebViewMessageType.APP_VERSION: {
                store.dispatch(setVersion(receivedData.payload));
                const response = await client.query({
                    query: gql`
                        query RequireForceUpdate($version: String!) {
                            requireForceUpdate(version: $version) {
                                ios
                                android
                            }
                        }
                    `,
                    variables: {
                        version: receivedData.payload,
                    },
                });
                // const result = await response.json();
                // if (result.data.requireForceUpdate) {
                //     router.navigate('/force-update', { replace: true });
                // }
                break;
            }
            case WebViewMessageType.SET_FONT_SCALE: {
                document.documentElement.style.setProperty(
                    '--font-scale',
                    receivedData.payload
                );
                break;
            }
            case WebViewMessageType.SET_TOP_INSET: {
                document.documentElement.style.setProperty(
                    '--top-inset',
                    receivedData.payload + 'px'
                );
                break;
            }
            case WebViewMessageType.SET_BOTTOM_INSET: {
                document.documentElement.style.setProperty(
                    '--bottom-inset',
                    receivedData.payload + 'px'
                );
                break;
            }
            case WebViewMessageType.METADATA_NET_INFO: {
                store.dispatch(setNetInfo(receivedData.payload));
                break;
            }
            case WebViewMessageType.METADATA_DEVICE_TYPE: {
                store.dispatch(setDeviceType(receivedData.payload));
                break;
            }
            case WebViewMessageType.METADATA_PHONE_CARRIER: {
                store.dispatch(setCarrier(receivedData.payload));
                break;
            }
            case WebViewMessageType.REMOTE_START:
                const value = receivedData.payload;
                if (scanned) break;
                if (
                    value &&
                    (value.indexOf('access-chargie.com') > -1 ||
                        value.indexOf('access.chargie.com') > -1)
                ) {
                    scanned = true;
                    store.dispatch(
                        setRequestedChargieId({
                            chargieId: new URL(value).pathname.split('/')[2],
                            startType: StartMethod.SCAN,
                        })
                    );
                } else if (value && /^\d{5}$/.test(value)) {
                    scanned = true;
                    store.dispatch(
                        setRequestedChargieId({
                            chargieId: value,
                            startType: StartMethod.SCAN,
                        })
                    );
                } else {
                    store.dispatch(setRequestedChargieId(undefined));
                    //TODO cleanup to check for a correct qr code
                    // store.dispatch(
                    //     setErrorType(RemoteErrorTypes.INVALID_STATION_CODE)
                    // );
                    // router.navigate('/', { replace: true });
                }
                setTimeout(() => {
                    scanned = false;
                }, 1000);

                break;
            case WebViewMessageType.ADD_RFID:
                const payload = receivedData.payload;
                if (payload && /^[0-9\b]+$/.test(payload))
                    store.dispatch(setDesiredRFID(payload));
                break;
            case WebViewMessageType.SET_MODAL_BACKGROUND:
                store.dispatch(setModalBackground(receivedData.payload));
                break;
            case WebViewMessageType.LOAD_DATA: {
                const { key, value } = receivedData.payload as LoadDataPayload;
                switch (key) {
                    case SecureStorageKeys.ACCESS_TOKEN:
                        store.dispatch(setAccessToken(value));
                        if (value) {
                            // Load all the user data here on token change
                            try {
                                const { data } = await client.query({
                                    query: getLatestUserDataQuery,
                                    fetchPolicy: 'no-cache',
                                });
                                // Save the data to the SecureStore for offline access
                                saveData(
                                    SecureStorageKeys.STATION_HISTORY,
                                    data.getUserRecentStations
                                );
                                saveData(
                                    SecureStorageKeys.HAS_PAYMENT,
                                    data.loggedInUser?.isCardOnFile ? '1' : ''
                                );
                                saveData(
                                    SecureStorageKeys.FIRST_NAME,
                                    data.loggedInUser?.nameFirst || ''
                                );
                                saveData(
                                    SecureStorageKeys.LAST_NAME,
                                    data.loggedInUser?.nameLast || ''
                                );
                                saveData(
                                    SecureStorageKeys.EMAIL,
                                    data.loggedInUser?.email || ''
                                );
                                // Do not need to store this offline
                                store.dispatch(
                                    setIsSubscribed(
                                        data.loggedInUser?.isSubscribed
                                    )
                                );
                                // Extract active station ID
                                if (
                                    data.loggedInUser?.currentTransaction
                                        ?.transactionCache
                                ) {
                                    const cache = JSON.parse(
                                        data.loggedInUser.currentTransaction
                                            .transactionCache
                                    );
                                    if (
                                        cache.isPenalty &&
                                        cache.chargeStatus.toUpperCase() ===
                                            ChargeStatus.COMPLETE
                                    ) {
                                        saveData(
                                            SecureStorageKeys.ACTIVE_CHARGIE_ID,
                                            cache.chargieId
                                        );
                                        store.dispatch(
                                            setActiveChargieId(cache.chargieId)
                                        );
                                        store.dispatch(
                                            setChargeStatus(
                                                ChargeStatus.FINISHING
                                            )
                                        );
                                    } else {
                                        saveData(
                                            SecureStorageKeys.ACTIVE_CHARGIE_ID,
                                            cache.chargeStatus.toUpperCase() ===
                                                'COMPLETE' ||
                                                cache.chargeStatus.toUpperCase() ===
                                                    'AVAILABLE'
                                                ? ''
                                                : cache.chargieId
                                        );
                                    }
                                    const duration =
                                        cache.chargeStartTime &&
                                        cache.timeComplete
                                            ? Math.abs(
                                                  (new Date(
                                                      cache.timeComplete
                                                  ).getTime() -
                                                      new Date(
                                                          cache.chargeStartTime
                                                      ).getTime()) /
                                                      1000
                                              )
                                            : 0;
                                } else {
                                    saveData(
                                        SecureStorageKeys.ACTIVE_CHARGIE_ID,
                                        ''
                                    );
                                    if (
                                        data.getUserMessage &&
                                        data.recentTransactionsForUser.length >
                                            0
                                    ) {
                                        const latestTransaction =
                                            data.recentTransactionsForUser[0];
                                        const dt1 = parseISO(
                                            latestTransaction.startedAt
                                        );
                                        const dt2 = parseISO(
                                            latestTransaction.stoppedAt
                                        );
                                        const duration = differenceInMinutes(
                                            dt2,
                                            dt1
                                        );
                                        const finishedTransaction: FinishedTransaction =
                                            {
                                                cost:
                                                    latestTransaction.amount ||
                                                    0,
                                                kwh:
                                                    (latestTransaction.wh *
                                                        100) /
                                                    100000,
                                                duration: duration,
                                            };
                                        store.dispatch(
                                            setInactiveChargieId(
                                                latestTransaction.station.qrCode
                                            )
                                        );
                                        store.dispatch(
                                            setFinishedTransaction(
                                                finishedTransaction
                                            )
                                        );
                                    }
                                }
                            } catch (e) {
                                console.error(e);
                            }
                        }
                        break;
                    case SecureStorageKeys.REFRESH_TOKEN:
                        return store.dispatch(setRefreshToken(value));
                    case SecureStorageKeys.DEVICE_TOKEN:
                        return store.dispatch(setDeviceToken(value));
                    case SecureStorageKeys.DEVICE_GUID:
                        return store.dispatch(setDeviceGuid(value));
                    case SecureStorageKeys.CHARGING_HISTORY:
                        return store.dispatch(
                            setChargingHistory(value ? JSON.parse(value) : [])
                        );
                    case SecureStorageKeys.BIOMETRICS_EXISTS:
                        return store.dispatch(setBiometricsExists(value));
                    case SecureStorageKeys.BIOMETRICS_TYPE:
                        return store.dispatch(setBiometricsType(value));
                    case SecureStorageKeys.BIOMETRICS_REFRESH_TOKEN:
                        return store.dispatch(setBiometricsRefreshToken(value));
                    case SecureStorageKeys.GOOGLE_PLAY_SERVICES_EXISTS:
                        return store.dispatch(
                            setGooglePlayServicesExists(value)
                        );
                    case SecureStorageKeys.APPLE_AUTH_EXISTS:
                        return store.dispatch(setAppleAuthExists(value));
                    case SecureStorageKeys.ACTIVE_CHARGIE_ID:
                        return store.dispatch(setActiveChargieId(value));
                    case SecureStorageKeys.ALLOWED_LOCATION_DATA:
                        return store.dispatch(
                            setAllowedLocationData(value !== 'false')
                        );
                    case SecureStorageKeys.PLATFORMPAY_EXISTS:
                        return store.dispatch(setPlatformPayExists(value));
                    case SecureStorageKeys.PLATFORM_OS:
                        return store.dispatch(setPlatformOs(value));
                    case SecureStorageKeys.STATION_HISTORY:
                        return store.dispatch(
                            setRecentStations(value ? JSON.parse(value) : [])
                        );
                    case SecureStorageKeys.HAS_PAYMENT:
                        return store.dispatch(setHasPaymentMethod(!!value));
                    case SecureStorageKeys.FIRST_NAME:
                        return store.dispatch(setFirstName(value || ''));
                    case SecureStorageKeys.LAST_NAME:
                        return store.dispatch(setLastName(value || ''));
                    case SecureStorageKeys.EMAIL:
                        return store.dispatch(setEmail(value || ''));
                }
                break;
            }
            case WebViewMessageType.DELETE_DATA: {
                const { key } = receivedData.payload as DeleteDataPayload;
                switch (key) {
                    case SecureStorageKeys.ACCESS_TOKEN:
                        return store.dispatch(setAccessToken(undefined));
                    case SecureStorageKeys.REFRESH_TOKEN:
                        return store.dispatch(setRefreshToken(undefined));
                    case SecureStorageKeys.STATION_HISTORY:
                        return store.dispatch(setRecentStations(undefined));
                    case SecureStorageKeys.CHARGING_HISTORY:
                        return store.dispatch(setChargingHistory(undefined));
                }
                break;
            }
            case WebViewMessageType.BIOMETRICS_PUBLIC_KEY:
                try {
                    await axios.post(
                        `${process.env.REACT_APP_AUTH_ENDPOINT}/biometrics/setup`,
                        {
                            access_token: store.getState().app.accessToken,
                            refresh_token: store.getState().app.refreshToken,
                            public_key: receivedData.payload,
                        },
                        { headers: await generateHeaders() }
                    );
                } catch (e) {
                    // if (e instanceof AxiosError) alert(e.message);
                }
                break;
            case WebViewMessageType.BIOMETRICS_SIGNATURE:
                try {
                    const result = await axios.post(
                        `${process.env.REACT_APP_AUTH_ENDPOINT}/biometrics/login`,
                        {
                            refresh_token:
                                store.getState().app.biometricsRefreshToken,
                            signature: receivedData.payload,
                            device_token: store.getState().app.deviceToken,
                            realm: 'pcse',
                        },
                        { headers: await generateHeaders() }
                    );
                    if (result.data.access_token) {
                        store.dispatch(
                            setAccessToken(result.data.access_token)
                        );
                        store.dispatch(
                            setRefreshToken(
                                store.getState().app.biometricsRefreshToken
                            )
                        );
                        saveData(
                            SecureStorageKeys.ACCESS_TOKEN,
                            result.data.access_token
                        );
                        saveData(
                            SecureStorageKeys.REFRESH_TOKEN,
                            store.getState().app.biometricsRefreshToken
                        );
                    }
                } catch (e) {
                    // if (e instanceof AxiosError) alert(e.message);
                }
                break;
            case WebViewMessageType.GOOGLE_TOKEN:
                try {
                    let result;
                    try {
                        result = await axios.post(
                            `${process.env.REACT_APP_AUTH_ENDPOINT}/google/login`,
                            {
                                token: receivedData.payload,
                                device_token: store.getState().app.deviceToken,
                                realm: 'pcse',
                            },
                            { headers: await generateHeaders() }
                        );
                    } catch (e: any) {
                        await client.mutate({
                            mutation: gql`
                                mutation RegisterUser($googleToken: String) {
                                    registerUser(googleToken: $googleToken)
                                }
                            `,
                            variables: {
                                googleToken: receivedData.payload,
                                isTracked: store.getState().signup.isTracked,
                            },
                        });
                        result = await axios.post(
                            `${process.env.REACT_APP_AUTH_ENDPOINT}/google/login`,
                            {
                                token: receivedData.payload,
                                device_token: store.getState().app.deviceToken,
                                realm: 'pcse',
                            },
                            { headers: await generateHeaders() }
                        );
                    } finally {
                        if (result) {
                            if (result.data.access_token) {
                                saveData(
                                    SecureStorageKeys.ACCESS_TOKEN,
                                    result.data.access_token
                                );
                                store.dispatch(
                                    setAccessToken(result.data.access_token)
                                );
                            }
                            if (result.data.refresh_token) {
                                saveData(
                                    SecureStorageKeys.REFRESH_TOKEN,
                                    result.data.refresh_token
                                );
                                store.dispatch(
                                    setRefreshToken(result.data.refresh_token)
                                );
                            }
                        }
                    }
                } catch (e) {
                    // if (e instanceof AxiosError) alert(e.message);
                }
                break;
            case WebViewMessageType.APPLE_TOKEN:
                try {
                    let result;
                    try {
                        result = await axios.post(
                            `${process.env.REACT_APP_AUTH_ENDPOINT}/apple/login`,
                            {
                                token: receivedData.payload,
                                device_token: store.getState().app.deviceToken,
                                realm: 'pcse',
                            },
                            { headers: await generateHeaders() }
                        );
                        if (result.data.axiosError)
                            throw result.data.axiosError;
                    } catch (e: any) {
                        await client.mutate({
                            mutation: gql`
                                mutation RegisterUser($appleToken: String) {
                                    registerUser(appleToken: $appleToken)
                                }
                            `,
                            variables: {
                                appleToken: receivedData.payload,
                                isTracked: store.getState().signup.isTracked,
                            },
                        });
                        result = await axios.post(
                            `${process.env.REACT_APP_AUTH_ENDPOINT}/apple/login`,
                            {
                                token: receivedData.payload,
                                device_token: store.getState().app.deviceToken,
                                realm: 'pcse',
                            },
                            { headers: await generateHeaders() }
                        );
                    } finally {
                        if (result) {
                            saveData(
                                SecureStorageKeys.ACCESS_TOKEN,
                                result.data.access_token
                            );
                            saveData(
                                SecureStorageKeys.REFRESH_TOKEN,
                                result.data.refresh_token
                            );
                            store.dispatch(
                                setAccessToken(result.data.access_token)
                            );
                            store.dispatch(
                                setRefreshToken(result.data.refresh_token)
                            );
                        }
                    }
                } catch (e) {
                    // if (e instanceof AxiosError) alert(e.message);
                }
                break;
            case WebViewMessageType.PLATFORM_PAY_PM:
                try {
                    store.dispatch(setPaymentMethod(receivedData.payload));
                    store.dispatch(setModalBackground(false));
                } catch (e) {
                    // if (e instanceof AxiosError) alert(e.message);
                }
                break;
            case WebViewMessageType.CREDIT_CARD_PM:
                store.dispatch(setPaymentMethod(receivedData.payload));
                store.dispatch(setModalBackground(false));
                break;
            case WebViewMessageType.CREDIT_CARD_INFO:
                store.dispatch(setCreditCard(receivedData.payload));
                store.dispatch(setModalBackground(false));
                break;
            case WebViewMessageType.WEBVIEW_GO_BACK:
                router.navigate(-1);
                break;
            case WebViewMessageType.REQUEST_LOCATION:
                try {
                    store.dispatch(
                        setLongitude(receivedData.payload.longitude)
                    );
                    store.dispatch(setLatitude(receivedData.payload.latitude));
                } catch (e) {
                    // if (e instanceof AxiosError) alert(e.message);
                }
                break;
            case WebViewMessageType.GET_SIGNUP_SUBSCRIPTION:
                store.dispatch(setSubscriptionStarted(receivedData.payload));
                break;
            case WebViewMessageType.REFRESH_PAGE:
                window.location.reload();
                break;
        }
    } catch (e: any) {
        console.error(e);
    }
}

// Android
document.addEventListener('message', messageHandler as any);
// iOS
window.addEventListener('message', messageHandler);
