export enum Environment {
    LOCAL = 'LOCAL',
    DEVELOP = 'DEVELOP',
    STAGING = 'STAGING',
    PRODUCTION = 'PRODUCTION',
}

// Keep this key list in sync with the mobile-app repo

export enum WebViewMessageType {
    INIT = 'INIT',
    APP_VERSION = 'APP_VERSION',
    SET_FONT_SCALE = 'SET_FONT_SCALE',
    SET_TOP_INSET = 'SET_TOP_INSET',
    SET_BOTTOM_INSET = 'SET_BOTTOM_INSET',
    SAVE_DATA = 'SAVE_DATA',
    LOAD_DATA = 'LOAD_DATA',
    DELETE_DATA = 'DELETE_DATA',
    LOGOUT = 'LOGOUT',
    REMOTE_START = 'REMOTE_START',
    HIDE_SCANNER = 'HIDE_SCANNER',
    SHOW_SCANNER = 'SHOW_SCANNER',
    MINIMIZE_SCANNER = 'MINIMIZE_SCANNER',
    MAXIMIZE_SCANNER = 'MAXIMIZE_SCANNER',
    SHOW_ADMIN = 'SHOW_ADMIN',
    SET_ENVIRONMENT = 'SET_ENVIRONMENT',
    SETUP_BIOMETRICS = 'SETUP_BIOMETRICS',
    DELETE_BIOMETRICS = 'DELETE_BIOMETRICS',
    BIOMETRICS_LOGIN = 'BIOMETRICS_LOGIN',
    BIOMETRICS_PUBLIC_KEY = 'BIOMETRICS_PUBLIC_KEY',
    BIOMETRICS_SIGNATURE = 'BIOMETRICS_SIGNATURE',
    GOOGLE_SIGNIN = 'GOOGLE_SIGNIN',
    GOOGLE_TOKEN = 'GOOGLE_TOKEN',
    APPLE_SIGNIN = 'APPLE_SIGNIN',
    APPLE_TOKEN = 'APPLE_TOKEN',
    LOG = 'LOG',
    PLATFORM_PAY = 'PLATFORM_PAY',
    CREDIT_CARD = 'CREDIT_CARD',
    PLATFORM_PAY_PM = 'PLATFORM_PAY_PM',
    CREDIT_CARD_PM = 'CREDIT_CARD_PM',
    CREDIT_CARD_INFO = 'CREDIT_CARD_INFO',
    SET_MODAL_BACKGROUND = 'SET_MODAL_BACKGROUND',
    HIDE_PAYMENT_MODAL = 'HIDE_PAYMENT_MODAL',
    OPEN_APP_STORE = 'OPEN_APP_STORE',
    OPEN_BIOMETRICS_SETTINGS = 'OPEN_BIOMETRICS_SETTINGS',
    SCAN_LOW_SIGNAL = 'SCAN_LOW_SIGNAL',
    QUEUE_LOW_SIGNAL = 'QUEUE_LOW_SIGNAL',
    QUEUE_REMOTE = 'QUEUE_REMOTE',
    SET_STATUS_BAR_LIGHT = 'SET_STATUS_BAR_LIGHT',
    SET_USER_INFO = 'SET_USER_INFO',
    OPEN_APP_SETTINGS = 'OPEN_APP_SETTINGS',
    ADD_RFID = 'ADD_RFID',
    METADATA_DEVICE_TYPE = 'METADATA_DEVICE_TYPE',
    METADATA_NET_INFO = 'METADATA_NET_INFO',
    METADATA_PHONE_CARRIER = 'METADATA_PHONE_CARRIER',
    WEBVIEW_GO_BACK = 'WEBVIEW_GO_BACK',
    REQUEST_LOCATION = 'REQUEST_LOCATION',
    SAVE_BAD_NETWORK_REQUESTS = 'SAVE_BAD_NETWORK_REQUESTS',
    GET_BAD_NETWORK_REQUESTS = 'GET_BAD_NETWORK_REQUESTS',
    SET_SIGNUP_SUBSCRIPTION = 'SET_SIGNUP_SUBSCRIPTION',
    GET_SIGNUP_SUBSCRIPTION = 'GET_SIGNUP_SUBSCRIPTION',
    HARD_RESET_APP = 'HARD_RESET_APP',
    CANCEL_REMOTE_START = 'CANCEL_REMOTE_START',
    DISMISS_LOW_SIGNAL_MODAL = 'DISMISS_LOW_SIGNAL_MODAL',
    OPEN_3DS_MODAL = 'OPEN_3DS_MODAL',
    REFRESH_PAGE = 'REFRESH_PAGE',
}

// Keep this key list in sync with the mobile-app repo
// Skip the native app keys as they are not relevant here
export enum SecureStorageKeys {
    // General application data - denoted by app.{name}
    ACCESS_TOKEN = 'app.accessToken',
    REFRESH_TOKEN = 'app.refreshToken',
    DEVICE_TOKEN = 'app.deviceToken',
    DEVICE_GUID = 'app.deviceGuid',
    // Need to differentiate this, as User A's biometrics won't work
    // after User B logs in afterwards
    BIOMETRICS_REFRESH_TOKEN = 'app.biometricsRefreshToken',
    BIOMETRICS_TYPE = 'app.biometricsType',
    BIOMETRICS_EXISTS = 'app.biometricsExists',
    GOOGLE_PLAY_SERVICES_EXISTS = 'app.googlePlayServicesExists',
    APPLE_AUTH_EXISTS = 'app.appleAuthExists',
    PLATFORMPAY_EXISTS = 'app.platformPayExists',
    PLATFORM_OS = 'app.platformOs',
    ALLOWED_LOCATION_DATA = 'app.allowedLocationData',
    // Webview data - web.{page}.{name}
    STATION_HISTORY = 'web.home.stationHistory',
    CHARGING_HISTORY = 'web.home.chargingHistory',
    ACTIVE_CHARGIE_ID = 'web.charge.activeChargieId',
    HAS_PAYMENT = 'web.user.hasPayment',
    EMAIL = 'web.user.email',
    FIRST_NAME = 'web.user.firstName',
    LAST_NAME = 'web.user.lastName',
    BAD_NETWORK_REQUESTS = 'app.badNetworkRequests',
    SIGNUP_SUBSCRIPTION_FLOW = 'web.user.signupSubscriptionFlow',
}

export enum RemoteErrorTypes {
    INVALID_STATION_CODE = 'InvalidStationCode',
    STATION_IN_USE = 'StationAlreadyCharging',
    UNAVAILABLE = 'Unavailable',
    NOT_SUBSCRIBED = 'NotSubscribed',
    NOT_CONFIRMED_USER = 'NotConfirmedUser',
    ALREADY_CHARGING = 'AlreadyCharging',
    PAYMENT_FAILURE = 'PaymentFailure',
    PAYMENT_METHOD_DECLINED = 'PaymentMethodDeclined',
    PAYMENT_METHOD_DECLINED_FUNDS = 'PaymentMethodDeclinedFunds',
    PAYMENT_METHOD_DECLINED_UPDATE = 'PaymentMethodDeclinedUpdate',
    ASSIGNED_PARKING = 'AssignedParking',
    EV_NOT_DETECTED = 'EVNotDetected',
    UNEXPECTED = 'Unexpected',
}

export enum Time {
    MOSTRECENT = 'MOSTRECENT',
    THIRTYDAYS = 'THIRTYDAYS',
    SIXMONTHS = 'SIXMONTHS',
    YEAR = 'YEAR',
    TOTAL = 'TOTAL',
}

export enum Guest {
    GUEST_CHECKOUT = 'Guest',
    PLATFORM_PAY = 'Platform',
    CREDIT_CARD = 'Credit',
}

export enum RemoteType {
    REMOTE_START = 'REMOTE_START',
    REMOTE_STOP = 'REMOTE_STOP',
}

export enum ChargeStatus {
    ABORTED = 'ABORTED',
    CHARGING = 'CHARGING',
    COMPLETE = 'COMPLETE',
    FINISHING = 'FINISHING',
    IDLE = 'IDLE',
    PENDING = 'PENDING',
    SUSPENDEDEV = 'SUSPENDEDEV',
    SUSPENDEDEVSE = 'SUSPENDEDEVSE',
    QUEUED = 'QUEUED',
    PREPARING = 'PREPARING',
    UNAVAILABLE = 'UNAVAILABLE',
    FAULTED = 'FAULTED',
    AVAILABLE = 'AVAILABLE',
    FINISHED = 'FINISHED',
    LOADING = 'LOADING',
    FINALIZED = 'FINALIZED',
}

export enum StripeTransactionTypes {
    DEBIT = 'Debit',
    CREDIT = 'Credit',
    SUBSCRIPTION = 'Chargie pro subscription',
    REFUND = 'Refund',
    AUTO = 'Auto-reload',
    RELOAD = 'Reload',
    SESSION = 'Charging session',
}

export enum ScannerType {
    STATION = 'STATION',
    RFID = 'RFID',
}

export enum StartMethod {
    SCAN = 'SCAN',
    NATIVE_SCAN = 'NATIVE_SCAN',
    MANUAL = 'MANUAL',
    RECENT = 'RECENT',
    RETRY = 'RETRY',
}
