import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
    selectActiveChargieId,
    selectHasPaymentMethod,
    selectRefreshToken,
    selectRequestedChargieId,
    setActiveChargieId,
    setChargeAmp,
    setChargeCost,
    setChargeKw,
    setChargeKwh,
    setChargeStartTime,
    setChargeStatus,
    setDesiredChargieId,
    setErrorType,
    setInactiveChargieId,
    setQueuedChargieId,
    setRequestedChargieId,
    setRetryChargieId,
} from '../appSlice';
import {
    ChargeStatus,
    RemoteType,
    SecureStorageKeys,
    StartMethod,
} from '../utils/enums';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    saveData,
    hideScanner,
    notifyLowSignalScan,
    notifyLowSignalQueue,
    queueRemote,
} from '../utils/webview/messages';
import { useNavigate } from 'react-router-dom';
import {
    selectContactInformation,
    selectPaymentMethod,
    setContactInformation,
    setPaymentMethod,
} from '../guestSlice';

const guestRemoteStartMutation = loader(
    './PricingDetails/guestRemoteStart.graphql'
);
const remoteStartMutation = loader('../gql/remoteStart.graphql');
const loggedInUser = loader('../gql/getUserPayment.graphql');

interface RemoteStartProviderProps {
    children: ReactNode;
}

interface RemoteStartContextType {
    chargieId: string | null | undefined;
}

const RemoteStartContext = createContext<RemoteStartContextType | undefined>(
    undefined
);

const RemoteStartProvider: React.FC<RemoteStartProviderProps> = ({
    children,
}) => {
    const dispatch = useAppDispatch();
    const [chargieId, setchargieId] = useState<string | null | undefined>(null);
    const requestedChargieId = useAppSelector(selectRequestedChargieId);
    const activeChargieId = useAppSelector(selectActiveChargieId);
    const hasPaymentMethod = useAppSelector(selectHasPaymentMethod);
    const navigate = useNavigate();
    const refreshToken = useAppSelector(selectRefreshToken);

    //guest checkout info
    const contactInformation = useAppSelector(selectContactInformation);
    const paymentMethod = useAppSelector(selectPaymentMethod);

    let scanned = false;
    const [
        remoteStart,
        { data: remoteStartData, loading: remoteStartLoading },
    ] = useMutation(remoteStartMutation);

    const [getUserDetails, { data: userPaymentData }] = useLazyQuery(
        loggedInUser,
        {
            fetchPolicy: 'no-cache',
        }
    );

    const [guestRemoteStart, { loading: guestRemoteStartLoading }] =
        useMutation(guestRemoteStartMutation);

    useEffect(() => {
        const attemptCharge = async () => {
            if (
                !remoteStartLoading &&
                requestedChargieId &&
                !scanned &&
                !activeChargieId
            ) {
                scanned = true;
                await startCharge(
                    requestedChargieId.chargieId,
                    requestedChargieId.startType
                );
                dispatch(setRequestedChargieId(null));
            }
        };
        attemptCharge();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestedChargieId]);

    const startCharge = async (desiredId: string, methodType: StartMethod) => {
        if (remoteStartLoading) return;
        dispatch(setInactiveChargieId(null));
        if (activeChargieId) {
            navigate('/session', { replace: true });
            return;
        } else if (!refreshToken) {
            if (paymentMethod && contactInformation) {
                try {
                    const response = await guestRemoteStart({
                        variables: {
                            input: {
                                phone: contactInformation,
                                qrCode: desiredId,
                                paymentMethod: paymentMethod,
                                method: requestedChargieId?.startType,
                            },
                        },
                    });
                    if (response.data?.guestRemoteStart) {
                        dispatch(setActiveChargieId(null));
                        saveData(SecureStorageKeys.ACTIVE_CHARGIE_ID, '');
                        navigate('/guest/success', {
                            replace: true,
                            state: { slide: true },
                        });
                    }
                } catch (e) {
                    const { graphQLErrors } = e as any;
                    if (graphQLErrors && graphQLErrors.length > 0) {
                        if (graphQLErrors[0].errorType === 'NotSubscribed')
                            navigate('/subscribe', { state: { slide: true } });
                        else dispatch(setErrorType(graphQLErrors[0].errorType));
                    }
                    navigate('/', { replace: true });
                } finally {
                    scanned = false;
                    dispatch(setDesiredChargieId(null));
                    dispatch(setRequestedChargieId(undefined));
                    dispatch(setPaymentMethod(null));
                    dispatch(setContactInformation(null));
                }
            } else {
                scanned = false;
                dispatch(
                    setDesiredChargieId({
                        chargieId: desiredId,
                        startType: methodType,
                    })
                );
                dispatch(setRequestedChargieId(undefined));
                navigate('/guest/pricing', { state: { slide: true } });
            }
        } else {
            if (!hasPaymentMethod) {
                const userDetails = await getUserDetails();
                if (
                    !userDetails.data ||
                    !userDetails.data.loggedInUser ||
                    !userDetails.data.loggedInUser.isCardOnFile
                ) {
                    dispatch(
                        setDesiredChargieId({
                            chargieId: desiredId,
                            startType: methodType,
                        })
                    );
                    navigate('/pay');
                    return;
                }
            }
            try {
                const result = await remoteStart({
                    variables: {
                        qrCode: desiredId,
                        method: methodType,
                    },
                });
                if (result?.data?.remoteStart) {
                    saveData(SecureStorageKeys.ACTIVE_CHARGIE_ID, desiredId);
                    dispatch(setErrorType(null));
                    dispatch(setRequestedChargieId(null));
                    dispatch(setActiveChargieId(desiredId));
                    hideScanner();
                    scanned = false;
                    navigate('/session', { replace: true });
                }
            } catch (e) {
                const { graphQLErrors } = e as any;
                dispatch(setRequestedChargieId(undefined));
                scanned = false;
                if (graphQLErrors && graphQLErrors.length === 0) {
                    notifyLowSignalScan(false);
                    dispatch(setQueuedChargieId(desiredId));
                    dispatch(setActiveChargieId(desiredId));
                    notifyLowSignalQueue(true);
                    queueRemote(desiredId, RemoteType.REMOTE_START);
                    dispatch(setChargeStatus(ChargeStatus.QUEUED));
                    dispatch(setChargeAmp(0));
                    dispatch(setChargeKwh(0));
                    dispatch(setChargeKw(0));
                    dispatch(setChargeCost(0));
                    dispatch(setChargeStartTime(null));
                    navigate('/session', { replace: true });
                    return;
                } else if (graphQLErrors && graphQLErrors.length > 0) {
                    dispatch(setActiveChargieId(null));
                    dispatch(setRetryChargieId(desiredId));
                    if (graphQLErrors[0].errorType === 'NotSubscribed') {
                        navigate('/subscribe');
                        return;
                    } else dispatch(setErrorType(graphQLErrors[0].errorType));
                } else {
                    dispatch(setActiveChargieId(null));
                }
                navigate('/', { replace: true });
            }
        }
    };

    return (
        <RemoteStartContext.Provider value={{ chargieId }}>
            {children}
        </RemoteStartContext.Provider>
    );
};

const useRemoteStart = (): RemoteStartContextType => {
    const context = useContext(RemoteStartContext);
    if (context === undefined) {
        throw new Error(
            'useRemoteStart must be used within a RemoteStartProvider'
        );
    }
    return context;
};

export { RemoteStartProvider, useRemoteStart };
